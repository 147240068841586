.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place the video behind other content */
    overflow: hidden;
  }
  
  .video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }