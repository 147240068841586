.post{
    display:flex;
    flex-direction: column;
    align-items: center;
    color:aliceblue;
}
.postImg{
    width:100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
}

.postDesc{
    padding: 0 100px;
    font-size: 24px;
    font-weight: 300;
    color: whitesmoke;
    margin-top: 50px;
}
.postLongDesc
{
    padding: 100px;
    padding-top: 50px;
    font-size: 18px;
    line-height: 24px;
}