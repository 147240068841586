.start-button button{
    height :5rem ;
    width : 8rem ;
    border-radius : 50%;
    background : #e8f4ff;
    overflow : hidden;
    cursor: pointer;
    margin-top: 26rem;
    margin-left: 40rem;

}

.navbar{
    height: 3rem;
    background-color: rgba(241, 102, 9, 0.79);
    color :#e8f4ff;
    align-items: center;
    display: flex;
    justify-content: space-around;
}
.list{
    display: flex;
    align-items: center;
    list-style: none;

}

.listItem{
    margin-right: 1.5rem;
    font-weight: 500;
    cursor: pointer;
}

.logo
{
    font-size:1.4rem;
    font-weight: bold;
}
.avatar{
    width:2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;

}
button
{
    color: black;
}
.home
{
    display:flex;
    padding: 50px 100px;
    justify-content: space-between;
    
}
.card{
    background-color:  rgba(0,0,0,0.45);
    width:30%;
    padding: 10px;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, -5px -4px 18px -3px rgba(0,0,0,0.96); 
    box-shadow: 0px 10px 13px -7px #000000, -5px -4px 18px -3px rgba(0,0,0,0.96);
    
}
.img{
    width:100%;
    height: 200px;
    object-fit: cover;
    margin: 20px 0px;
}

.title
{
    color : #e8f4ff;
}
.desc{
    color : #333;
    margin-bottom: 20px;
    line-height: 25px;
    color: #e8f4ff;

}

.cardButton
{
    border:none;
    border-radius: 10px;
    background-color: rgba(255, 123, 35, 0.859);
    padding: 10px;
    color: #e8f4ff;
    font-weight: bold;
    cursor : pointer;
}

.link{
    color:inherit;
    text-decoration: none;
}

@media screen and (max-width : 992px)
{
    .card {
        width:100%;
        margin-bottom : 50px;
    }
    .home{
        display:block;
    }
}