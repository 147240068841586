.Login{
    color :aliceblue;

    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper{
    width:60%;
    height: 75%;
    background-color:  rgba(0,0,0,0.45);
    -webkit-box-shadow: 0px 10px 13px -7px #000000, -5px -4px 18px -3px rgba(0,0,0,0.96); 
    box-shadow: 0px 10px 13px -7px #000000, -5px -4px 18px -3px rgba(0,0,0,0.96);
    display: flex;
    align-items: center;
    border-radius: 20px;

}

.left,.right{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;


}
.center{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.center .or {
    border:2px solid rgba(222,222,222,0.45);
    border-radius: 50%;
    padding: 10px;
    background-color: rgba(0,0,0,1);
    font-weight: bold;
    z-index: 1;
}
.center .line {
    width: 0.5px;
    height: 65%;
    background-color: rgba(241, 102, 9, 0.6);
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 0;


}

.wrapper .loginButton
{
    width: 150px;
    padding:15px 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
    
}
.google{
    background-color: #df4930;
}
.facebook{
    background-color: #507cc0;

}
.github{
    background-color: #000000;
}
.icon-login{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.wrapper input{
    width: 200px;
    padding: 15px 25px;
    margin-bottom: 20px;


}

.submit{
    width: 200px;
    background-color: rgba(241, 102, 9, 0.79);
    font-weight: bold;
    border:none;
    padding: 15px 20px;
    border-radius: 5px ;
    color : white;
    cursor: pointer;
}

.loginTitle{
    position: absolute;
    top:150px;

}