body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }


.day-night
{
  color: rgb(40, 40, 28);
  margin:50px 0px 0px 50px;
  background-color: #d2e1ff;
  border-radius: 3%;
  width: 40%;
  box-shadow:  5px 5px 10px rgba(0,0,0,0.3);
  border: 3px solid #a0b9ff;
  display:flex;
  justify-content: space-between;
  
  

}
.day-night .day
{
  height: 3rem;
  width: 50%;
  background-color: rgba(250, 170, 43, 0.858);
}
.day-night .night 
{
  width: 50%;
}
.timer 
{
  margin-top: 15px;
  margin-left: 13px;
  margin-right: 13px;
  display:flex;
  justify-content: space-between;
}
  .chat-container {
    overflow: auto;
    width: 40%;
    margin-top: 20px;
    margin-left: 50px;
    border: 3px solid #a0b9ff;
    background-color: #f9f9f9;
    max-height :80%;
    height: 64vh;
  }
  .chat-message {
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
  }
  .user-message {
    text-align: right;
    background-color: #b2d8b2;
    margin: 10px;
  
  }
  .bot-message {
    text-align: left;
    background-color: #a3c7e9;
  }

.input-message{
  margin-left: 50px;
  width: 40%;
  display:flex;
  border: 3px solid #a0b9ff;
}
.input-message input{
  width: 90%;
}
.input-message button{
  width: 10%;
  height: 40px;
}

.input-message button p{
  font-size: 30px;
  margin-top: 3px;
}